import { SIZES } from '@fsd/shared/constants';
import { WEIGHTS } from '@fsd/shared/ui/a-text/const';

export const A_STATUS_SCREEN_SIZES: Pick<typeof SIZES, 'L' | 'XL'> = {
  L: SIZES.L,
  XL: SIZES.XL,
} as const;

export type AStatusScreenSize = typeof A_STATUS_SCREEN_SIZES[keyof typeof A_STATUS_SCREEN_SIZES];

export interface AStatusScreenProps {
  readonly size: AStatusScreenSize;
  readonly imgPath: string;
  readonly title: string;
  readonly subtitle?: string;
  readonly contentClasses?: string;
}

export const IMG_SIZES: Record<AStatusScreenSize, number> = {
  [A_STATUS_SCREEN_SIZES.L]: 56,
  [A_STATUS_SCREEN_SIZES.XL]: 96,
} as const;

export const TITLE_SIZES: Record<AStatusScreenSize, (typeof SIZES)[keyof typeof SIZES]> = {
  [A_STATUS_SCREEN_SIZES.L]: SIZES.M,
  [A_STATUS_SCREEN_SIZES.XL]: SIZES.XL,
} as const;

export const SUBTITLE_SIZES: Record<AStatusScreenSize, (typeof SIZES)[keyof typeof SIZES]> = {
  [A_STATUS_SCREEN_SIZES.L]: SIZES.S,
  [A_STATUS_SCREEN_SIZES.XL]: SIZES.M,
} as const;

export const SUBTITLE_WEIGHT: Record<AStatusScreenSize, (typeof WEIGHTS)[keyof typeof WEIGHTS]> = {
  [A_STATUS_SCREEN_SIZES.L]: WEIGHTS.REGULAR,
  [A_STATUS_SCREEN_SIZES.XL]: WEIGHTS.SEMIBOLD,
} as const;
