import { useHttpClient } from '../../http/index';
import { CopyTradingAccounts, CreateCopyTradingAccount } from './types';

const { get, post } = useHttpClient();

export const useCopyTradingApi = () => {
  const getCopyTradingAccounts = async () => {
    const data = await get<CopyTradingAccounts>('/v1/office/copy_trading');
    return data;
  };

  const createPaymentAccount = async () => {
    const data = await post<CreateCopyTradingAccount>('/v1/office/copy_trading/create_payment_account');
    return data;
  };

  const getCopyTradingPlatformUrl = async () => {
    const data = await get<{ platformUrl: string }>('v1/office/copy_trading/platform_url');
    return data;
  };

  return {
    getCopyTradingAccounts,
    createPaymentAccount,
    getCopyTradingPlatformUrl,
  };
};
