import { RouteRecordRaw } from 'vue-router';
import { COPY_TRADING_ROUTES } from '@fsd/shared/constants/routes';

export const CopyTradingRoutes: RouteRecordRaw[] = [
  {
    name: COPY_TRADING_ROUTES.COPY_TRADING.NAME,
    path: COPY_TRADING_ROUTES.COPY_TRADING.PATH,
    meta: {
      title: 'pageTitle.copyTrading',
      verification: 'full',
      verificationModalText: 'copyTrading.verificationModal.text',
    },
    children: [
      {
        name: COPY_TRADING_ROUTES.INVESTOR.NAME,
        path: COPY_TRADING_ROUTES.INVESTOR.PATH,
        component: () => import('./page-copy-trading.vue'),
      },
      {
        name: COPY_TRADING_ROUTES.TRADER.NAME,
        path: COPY_TRADING_ROUTES.TRADER.PATH,
        component: () => import('./page-copy-trading.vue'),
      },
    ],
  },
];
