import { RouterRoute } from '@fsd/shared/types';

export const FINANCE_ROUTES: Record<string, RouterRoute> = {
  FINANCE_WITHDRAW: {
    PATH: '/finance/withdraw',
    NAME: 'finance-withdraw',
  },
  FINANCE_WITHDRAW_REQUEST_CREATED: {
    PATH: '/finance/withdraw/request-created',
    NAME: 'finance-withdraw-request-created',
  },
  CREATE_PAYMENT_METHOD: {
    PATH: '/finance/create-payment-method',
    NAME: 'finance-create-payment-method',
  },
  TRANSFER: {
    PATH: '/finance/transfer',
    NAME: 'transfer',
  },
};
