import { AuthApi } from '@amarkets/atlas/esm/lib/auth-api';
import { AccountApi } from '@amarkets/atlas/esm/lib/account-api';
import { TwoFactorApi } from '@amarkets/atlas/esm/lib/two-factor-api';
import { IdentifyApi } from '@amarkets/atlas/esm/lib/identify-api';
import { AgoraApi } from '@amarkets/atlas/esm/lib/agora-api';
import { OfficeApi } from '@amarkets/atlas/esm/lib/office-api';
import { PremiumAnalyticsApi } from '@amarkets/atlas/esm/lib/premium-analytics-api';
import { DepositApi } from '@amarkets/atlas/esm/lib/deposit-api';
import { ExchangeApi } from '@amarkets/atlas/esm/lib/exchange-api';
import { DocVerifierApi } from '@amarkets/atlas/esm/lib/doc-verifier-api';

export function createApiServices({ httpClient, config }) {
  return {
    accountApi: new AccountApi(httpClient, config.API.EXTERNAL),
    twoFactorApi: new TwoFactorApi(httpClient, config.API.AUTH),
    authApi: new AuthApi(httpClient, config.API.AUTH),
    identifyApi: new IdentifyApi(httpClient, config.API.EXTERNAL),
    agoraApi: new AgoraApi(httpClient, config.API.AGORA),
    officeApi: new OfficeApi(httpClient, config.API.EXTERNAL),
    premiumAnalyticsApi: new PremiumAnalyticsApi(httpClient, config.API.EXTERNAL),
    depositApi: new DepositApi(httpClient, config.API.DEPOSIT),
    exchangeApi: new ExchangeApi(httpClient, config.API.EXTERNAL),
    docVerifierApi: new DocVerifierApi(httpClient, config.API.DOC_VERIFIER),
  };
}
